import React from 'react';

const AboutListing = (props) => {
    return (
        <>
            <li className="nav-item banner-common-box" >
            <a className={"nav-link " + (props.active === props.id ? "active" : "")} data-toggle="tab"
                    href="javascript:void(0)" onClick={() => props.tabChange(props.id)}>
                    <div className="banner-cover-div">
                        <div className="banner-content">
                            <img src={props.imgSrc} />
                            <h5>{props.title}</h5>
                        </div>
                    </div>
                </a>
            </li>
        </>
    )
}

export default AboutListing;
