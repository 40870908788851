import React from 'react';
import image1 from "../Images/our-services-image-1.png";
import image3 from "../Images/our-services-image-3.png";

const LogisticService = () => {
  return (
    <>
    <section>
        <div className="other-services-main-div">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-common-title other-services-title text-center">
                            <h2>Other Services</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 other-services-div">
                        <div className="other-services-box-cover-div text-center">
                            <div className="custom-clearance-image common-image">
                                <img src={image1}/>
                            </div>
                            <div className="our-services-content">
                                <h4>Custome Clearance in UAE</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 other-services-div">
                        <div className="other-services-box-cover-div text-center">
                            <div className="custom-clearance-image common-image">
                                <img src={require(`../Images/our-services-image-2.png`)}/>
                            </div>
                            <div className="our-services-content">
                                <h4>Free Auction Account</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 other-services-div">
                        <div className="other-services-box-cover-div text-center">
                            <div className="custom-clearance-image common-image">
                                <img src={image3} />
                            </div>
                            <div className="our-services-content">
                                <h4>Sell your car at Marhaba Auction 1</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
      
    </>
  )
}

export default LogisticService;
