import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {Routes, Route, Navigate} from "react-router-dom";
import NavigationBar from './Components/NavigationBar';
import "./App.css";
import LogisticService from './Components/LogisticService';
import About from './Components/About';
import AutoShipping from './Components/AutoShipping';
import WareHousing from './Components/WareHousing';
import LandTransport from './Components/LandTransport';
import SellCars from './Components/SellCars';
import BuyCars from './Components/BuyCars';
import Contact from './Components/Contact';
import RequestQuote from './Components/RequestQuote';
import Login from './Components/Login';
import Home from './Components/Home';
import Footer from './Components/Footer';
import NotFound from './Components/NotFound';


const App = () => {
  return (
    <> 
    <NavigationBar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/service" element={<LogisticService />} />
      <Route path="/about" element={<About />} /> 
      <Route path="/buycars" element={<BuyCars />} />
      <Route path="/sellcars" element={<SellCars />} />
      <Route path="/contact" element={<Contact />} />
      {/* <Route path="/quote" element={<RequestQuote />} /> */}
      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<Navigate to ="/" />}/>
     
    </Routes>
    <RequestQuote />
    <Footer />
     
    </>
  )
}

export default App;
