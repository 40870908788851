import React, {useState} from 'react';

const Contact = () => {
    const [data, setData] = useState({
        fullname:'',
        phone:'',
        email:'',
        msg:''
    });
    let val = {...data}
    // const [error, setError] = useState(false)
    const  [error, setError] = useState("")

    const inputEvent = (event) =>{
       
        const {name,value} = event.target;
        setData((preVal) => {
           return {
               ...preVal,
               [name]:value //dynamic value
           };
        });

    }
    const formSubmit = (e) =>{
        e.preventDefault();
      
        setError("Required")
       
    }

    return (
        <>
            <div className="my-5">
                <h1 className="text-center">Contact Us</h1>
            </div>
            <div className="container contact_div">
                <div className="row">
                    <div className="col-md-6 col-10 mx-auto">
                    <form onSubmit={formSubmit}>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Full Name</label>
                            <input type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Your Name"
                            name="fullname"
                            value={data.fullname}
                            onChange={inputEvent}/>
                        </div>
                        {error && (!data.fullname || (data.fullname && data.fullname.trim() === "") ) ? 
                        <span style={{ color: "red" }}>{error}</span> : ""}

                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Phone</label>
                            <input type="number"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Mobile Number"
                            name="phone"
                            value={data.phone}
                            onChange={inputEvent}/>
                        </div>
                        {error && (!data.phone || (data.phone && data.fullname.trim() === "") ) ? 
                        <span style={{ color: "red" }}>{error}</span> : ""}

                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
                            <input type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="name@example.com"
                            name="email"
                            value={data.email}
                            onChange={inputEvent}/>
                        </div>
                        {error && (!data.email || (data.email && data.fullname.trim() === "") ) ? 
                        <span style={{ color: "red" }}>{error}</span> : ""}
                        
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
                            <textarea className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            name="msg"
                            value={data.msg}
                            onChange={inputEvent}>
                            </textarea>
                        </div>
                        {error && (!data.msg || (data.msg && data.fullname.trim() === "") ) ? 
                        <span style={{ color: "red" }}>{error}</span> : ""}
                        <div className="col-12">
                            <button className="btn btn-outline-primary" type="submit">Submit form</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;