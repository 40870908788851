import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-div">
            <div className="container">
                <div className="row footer-cover-div">
                    <div className="col-sm-6 col-md-6 col-lg-3 footer-columns">
                        <div className="location-footer-main-div">
                            <div className="footer-common-title">
                                <h2>LOCATION</h2>
                            </div>
                            <div className="location-detail-div">
                                <div className="location-detail-div">
                                    <ul>
                                        <li>Marhaba Auto Auction, 2nd inductial area po box 25628 sharjah, UAE</li>
                                        <li><a href="tel:+971 50 5785074">+971 50 5785074</a></li>
                                        <li>
                                            <a href="mailto:info@marhabaautoauction.com">
                                        info@marhabaautoauction.com</a>
                                        </li>
                                        <li>Mon - Fri : 09am to 06pm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 footer-columns">
                        <div className="quick-links-div">
                            <div className="footer-common-title">
                                <h2>QUICK LINKS</h2>
                            </div>
                            <div className="footer-menu-link-div footer-menu-link-common-div">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/">All vechicles</a></li>
                                    <li><a href="/">Auction Vechicle</a></li>
                                    <li><a href="/">Future Vechicle</a></li>
                                    <li><a href="/">Buy Now Vechicle</a></li>
                                    <li><a href="/">Auction</a></li>
                                    <li><a href="/">Contact us</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 footer-columns">
                        <div className="aboutus-footer-main-div">
                            <div className="footer-common-title">
                                <h2>ABOUT US</h2>
                            </div>
                            <div className="about-us-link-div footer-menu-link-common-div">
                                <ul>
                                    <li><NavLink to="/about">About Us</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 footer-columns">
                        <div className="support-footer-main-div">
                            <div className="footer-common-title">
                                <h2>SUPPORT</h2>
                            </div>
                            <div className="support-link-div footer-menu-link-common-div">
                                <ul>
                                    <li><a href="/">Support</a></li>
                                    <li><a href="/">Terms & condition</a></li>
                                    <li><a href="/">Privacy policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-main-div">
            <div className="container">
                <div className="row footer-copyright-div">
                    <div className="col-sm-12 col-md-12">
                        <div className="copy-right-text text-center">
                            <p>Copyright ©2020 <NavLink to="/">MARHABA AUCTION CO LLC.</NavLink> All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer;
