import React, { useState } from 'react';
import { AboutData } from './AboutData';
import AboutListing from './AboutListing';
import AutoShipping from './AutoShipping';
import WareHousing from './WareHousing';
import LandTransport from './LandTransport';
import SellCars from './SellCars';

const About = () => {
    const [active, setActive] = useState(1);
    const tabChange = (id) => {
        setActive(id);
    }


    return (
        <>
            <section>
                <div className="tabs-container-fulid">
                    <div className="container">
                        <ul className="nav nav-tabs banner-row-boxes" >
                            {AboutData.map((val, index) => {
                                const { title } = val
                                return (
                                    <AboutListing
                                        key={index}
                                        id={val.id}
                                        links={val.links}
                                        imgSrc={val.imgSrc}
                                        title={title}
                                        active={active}
                                        tabChange={tabChange}
                                    />
                                )
                            })
                            }
                        </ul>
                        <div className="tab-content">
                            <AutoShipping active={active} />
                            <WareHousing active={active} />
                            <LandTransport active={active} />
                            <SellCars active={active} />
                          
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;



