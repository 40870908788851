import React from 'react';
import {Field, ErrorMessage} from 'formik';


const Input = (props) => {
    const {label, name, ...rest} = props;
  return (
    <>
        <label htmlFor={name}>{label}</label>
         <Field id={name} name={name} {...rest} className="form-control" />
         <ErrorMessage name={name}>
         {(errormsg) => <div className="error">{errormsg}</div>}
         </ErrorMessage>
    </>
  )
}

export default Input;
