import React from 'react';
import { NavLink } from 'react-router-dom';
import shippingLogo from "../Images/M1-Shipping-logo.png";

const NavigationBar = () => {
    return (
        <>
            <header>
                <div className="container-fluid header-menu">
                    <div className="container">
                        <nav className="navbar navbar-expand-md">
                            <div className="col-sm-2 col-md-2 col-lg-2 left-logo">
                                <NavLink className="navbar-brand" to="/">
                                    <img src={shippingLogo} alt="logo" />
                                </NavLink>
                            </div>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-list-2" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <div className="cross-icon-mobile">
                                </div>
                            </button>
                            <div className="col-sm-10 col-lg-10 col-md-10 menu-right-part">
                                <div className="collapse navbar-collapse" id="navbar-list-2">
                                    <ul className="navbar-nav">
                                        <li className="nav-item active">
                                            <NavLink to="/" className="nav-link"> Home </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/service" className="nav-link">Logistic Services</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/buycars" className="nav-link">Buy Cars</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/sellcars" className="nav-link">Sell Cars</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact" className="nav-link">Contact</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/quote" className="nav-link common-btn ruquest-quote">Request a Quote</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/login" className="nav-link common-btn login">Login</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )
}

export default NavigationBar;
