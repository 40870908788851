import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <>
      <section className="banner-section">
        <div className="main-banner-div">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner-content-mani-div">
                  <div className="banner-sub-title">
                    <h6>M1 Shipping Services</h6>
                  </div>
                  <div className="banner-main-title">
                    <h1>Get Your Cars Shipped <br />from USA to UAE</h1>
                  </div>
                  <div className="contact-now-cover-btn">
                    <Link to="/contact" className="contact-now-btn">Contact Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Header;
