import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from './FormikControl';

const Login = () => {
  const initialValues = {
    email: '',
    password: ''
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
    password: Yup.string().required('Required')
  })

  const onSubmit = (values, onSubmitProps) => {
    console.log('Form data', values);
    console.log('submit props', onSubmitProps);
    onSubmitProps.setSubmitting(false)
    onSubmitProps.resetForm()
  }


  return (
    <div className="login">
      <div className="row">
        <div className="col-md-12">
          <h1>Login Form</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
            {
              formik => {
                {/* console.log(formik) */ }
                return <Form>
                  <FormikControl
                    control='input'
                    type='email'
                    label='Email'
                    name='email' />
                  <FormikControl
                    control='input'
                    type='password'
                    label='Password'
                    name='password' />
                  <button type='submit' className='btn btn-primary' disabled={!formik.isValid || formik.isSubmitting}>
                    Submit
                  </button>
                </Form>
              }
            }

          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Login;


{/* import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";

const initialValues = {
  email: '', 
  password: ''
}
const onSubmit = (values) => {
  console.log('Form data', values)
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup.string().required('Required')
})


const validate = values => {

  let errors = {}
  if (!values.email) {
    errors.email = "Field is required"
  }
  if (!values.password) {
    errors.password = "Field is required"
  }
  return errors;
}

const Login = () => {


  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <label htmlFor="email">Email</label>
          <Field type="email" name="email" id="email"/>
          <ErrorMessage name="email" component='div'/>
          <br />

          <label htmlFor="password">Password</label>
          <Field type="password" name="password" id="password"/>
          <ErrorMessage name="password">
          {(errorMsg) => <div className="error">{errorMsg}</div>}
          </ErrorMessage>
          <br />

          <button type="submit">Submit</button>
        </Form>
      </Formik>
    </>
  )
}

export default Login; */}

