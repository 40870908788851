import React from 'react';
import Header from "./Header";
import About from './About';
import LogisticService from "./LogisticService";
import RequestQuote from "./RequestQuote";

const Home = () => {
    return (
        <>
            <Header />
            <About />
            <LogisticService />
        </>
    )
}

export default Home;
