import React from 'react';
import Aboutus from "../Images/about-us-image.png";
import {Link} from 'react-router-dom';

const SellCars = (props) => {
  return (
    <>
      <div id="sellyourcar" className={"tab-pane " + (props.active == 4 ? "active" : "")}>
        <div className="about-company-div">
          <div className="container">
            <div className="row">
              <div className="col-md-6 about-us-left-div">
                <div className="about-image">
                  <img src={Aboutus} />
                </div>
              </div>
              <div className="col-md-6 about-us-right-div">
                <div className="main-common-title about-title">
                  <h2>Sell Your Car</h2>
                </div>
                <div className="about-content">
                  <p className="bold">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                    ex ea commodo consequat
                  </p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                    ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                    mollit anim id est laborum.
                  </p>
                </div>
                <div className="find-out-more-btn">
                <Link to="/" className="find-out-btn">Find Out More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}

export default SellCars;
