import React from 'react';
import worldIcon from "../Images/world-icon.png";
import CarSoldIcon from "../Images/car-sold-icon.png";
import Carousel from "react-bootstrap/Carousel";

const RequestQuote = () => {
    return (
        <>
            <section>
                <div className="testimonial-slider-div">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="main-common-title testimonal-title text-center">
                                    <h2>Testimonials</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div id="demo" className="carousel slide" data-ride="carousel">
                                    {/* <ul className="carousel-indicators">
                                        <li data-target="#demo" data-slide-to="0" className="active"></li>
                                        <li data-target="#demo" data-slide-to="1"></li>
                                        <li data-target="#demo" data-slide-to="2"></li>
                                        <li data-target="#demo" data-slide-to="3"></li>
                                        <li data-target="#demo" data-slide-to="4"></li>
                                    </ul> */}
                                    {/* <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="carousel-caption">
                                        <p> A respectable and arranged auction and its employees are a very respectable group from the owner to the youngest member in the group and I advise everyone to visit the auction</p>
                                        <span> Faisal Abu Shaheen, my class, Oman</span>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="carousel-caption">
                                        <p> A respectable and arranged auction and its employees are a very respectable group from the owner to the youngest member in the group and I advise everyone to visit the auction</p>
                                        <span> Faisal Abu Shaheen, my class, Oman</span>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="carousel-caption">
                                        <p> A respectable and arranged auction and its employees are a very respectable group from the owner to the youngest member in the group and I advise everyone to visit the auction</p>
                                        <span> Faisal Abu Shaheen, my class, Oman</span>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="carousel-caption">
                                        <p> A respectable and arranged auction and its employees are a very respectable group from the owner to the youngest member in the group and I advise everyone to visit the auction</p>
                                        <span> Faisal Abu Shaheen, my class, Oman</span>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="carousel-caption">
                                        <p> A respectable and arranged auction and its employees are a very respectable group from the owner to the youngest member in the group and I advise everyone to visit the auction</p>
                                        <span> Faisal Abu Shaheen, my class, Oman</span>
                                    </div>
                                </div>
                            </div> */}
                                    <Carousel variant="dark">
                                        <Carousel.Item>
                                            <Carousel.Caption>
                                                <p> A respectable and arranged auction and its employees are a very respectable group from the owner to the youngest member in the group and I advise everyone to visit the auction</p>
                                                <span> Faisal Abu Shaheen, my class, Oman</span>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <Carousel.Caption>
                                                <p> A respectable and arranged auction and its employees are a very respectable group from the owner to the youngest member in the group and I advise everyone to visit the auction</p>
                                                <span> Faisal Abu Shaheen, my class, Oman</span>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <Carousel.Caption>
                                                <p> A respectable and arranged auction and its employees are a very respectable group from the owner to the youngest member in the group and I advise everyone to visit the auction</p>
                                                <span> Faisal Abu Shaheen, my class, Oman</span>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <Carousel.Caption>
                                                <p> A respectable and arranged auction and its employees are a very respectable group from the owner to the youngest member in the group and I advise everyone to visit the auction</p>
                                                <span> Faisal Abu Shaheen, my class, Oman</span>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <Carousel.Caption>
                                                <p> A respectable and arranged auction and its employees are a very respectable group from the owner to the youngest member in the group and I advise everyone to visit the auction</p>
                                                <span> Faisal Abu Shaheen, my class, Oman</span>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section>
                <div className="feature-cover-box-div">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="feature-box">
                                    <div className="icon-truck">
                                        <img src={worldIcon} alt="Image" />
                                    </div>
                                    <div className="content">
                                        <h6>UAE's #1</h6>
                                        <p>Car Auction Portal</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="feature-box">
                                    <div className="icon-support"><img src={CarSoldIcon} alt="Image" /></div>
                                    <div className="content">
                                        <h6>Live Auction</h6>
                                        <p>Twice a week</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="feature-box">
                                    <div className="icon-money"><img src={require(`../Images/tag-icon.png`)} alt="Image" /></div>
                                    <div className="content">
                                        <h6>Offers</h6>
                                        <p>Best price on used cars</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="feature-box">
                                    <div className="icon-return"><img src={require(`../Images/ship-icon.png`)} alt="Image" /></div>
                                    <div className="content">
                                        <h6>Shipping</h6>
                                        <p>Cars shipment from USA/Canada</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RequestQuote;
